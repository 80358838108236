import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={"Threaded Binary Tree | A Helpful Line-by-Line Code Tutorial"}
				subHeader={""}
				description={
					"A binary tree is threaded by making all right child pointers that would normally be null point to the inorder successor of the node (if it exists), and all left child pointers that would normally be null point to the inorder predecessor of the node."
				}
				keywords={[]}
				embeddedVideo={"HFbA0klBKIg"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/822a78219714319bee32c58daaf70275/raw/6a4a63fc21e1db4c79f81a88c3934ab9c3bc6121/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
